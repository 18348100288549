<template>
  <div>
    <v-card>
      <v-card-title>
        <span color="primary" class="headline">Invoice</span>
      </v-card-title>

      <v-card-text>
        <!-- redirect button -->
        <v-btn color="accent" elevation="23" x-large @click="createInvoice"
          >create Invoice</v-btn
        >
        <v-col cols="12" md="10">
          <v-overlay :value="loading">
            <v-progress-circular
              indeterminate
              size="64"
            ></v-progress-circular> </v-overlay
        ></v-col>
      </v-card-text>
      <v-col cols="12" md="12">
        <v-data-table
          :headers="invoicesHeader"
          :items="invoices"
          :items-per-page="5"
          class="elevation-1 "
        >
          <template v-slot:top>
            <v-toolbar flat class="mb-7">
              <!-- delete item -->
              <v-dialog v-model="dialogDelete" max-width="500px">
                <v-card>
                  <v-card-title class="headline"
                    >Are you sure you want to delete this user?</v-card-title
                  >
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="closeDelete"
                      >Cancel</v-btn
                    >
                    <v-btn
                      color="blue darken-1"
                      text
                      @click="deleteItemConfirm()"
                      >OK</v-btn
                    >
                    <v-spacer></v-spacer>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template
            v-slot:item.actions="{ item }"
            style="textAlign:right !important"
          >
            <v-icon
              color="primary"
              small
              class="mr-2"
              @click="editRecord(item)"
            >
              mdi-pencil
            </v-icon>

            <v-icon
              color="error"
              small
              class="mr-2"
              @click="deleteRecord(item)"
            >
              mdi-delete
            </v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-card>
  </div>
</template>

<script>
import ApiService from "@/services/api.service";

export default {
  props: ["tag_id", "dialog"],
  data() {
    return {
      loading: false,
      invoices: [],
      record: null,
      dialogDelete: false,
      invoicesHeader: [
        { text: "id", value: "id" },
        { text: "payment_status", value: "outto[0].result" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  methods: {
    editRecord(record) {
      this.$router.push(`/invoiceproduct/${record.id}`);
    },
    async createInvoice() {
      console.log("createInvoice");
      const { data } = await ApiService.post("items/invoice", {});
      console.log(data);
      this.$router.push(`/invoiceproduct/${data.data.id}`);
    },
    async loadInvoices() {
      const products = await ApiService.get(
        "items/invoice?fields=id,outto.*,outto.result,payment_status,products.product_id.id,products.product_id.arabic_title,products.quantity,products.price,products.currency_code,products.discount,products.subtotal,created_on&sort=-id"
      );
      this.invoices = products.data.data;
      console.log(this.invoices);
    },
    async deleteItemConfirm() {
      try {
        this.loading = true;
        await ApiService.delete(`items/invoice/${this.record.id}`);
        this.loadInvoices();
        this.loading = false;
        this.closeDelete();

        alert("Product deleted successfully");
      } catch (error) {
        console.log(error);
      }
    },
    deleteRecord(record) {
      this.record = record;
      this.dialogDelete = true;
    },
    closeDelete() {
      this.record = null;
      this.dialogDelete = false;
    },
  },
  async mounted() {
    this.loadInvoices();
  },
};
</script>
<style lang="css">
@import "~quill/dist/quill.core.css";
@import "~quill/dist/quill.snow.css";
@import "~quill/dist/quill.bubble.css";
.image-area {
  position: relative;
  width: 50%;
  background: #333;
}
.image-area img {
  max-width: 100%;
  height: auto;
}
.remove-image {
  display: none;
  position: absolute;
  top: -10px;
  right: -10px;
  border-radius: 10em;
  padding: 2px 6px 3px;
  text-decoration: none;
  font: 700 21px/20px sans-serif;
  background: #555;
  border: 3px solid #fff;
  color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5), inset 0 2px 4px rgba(0, 0, 0, 0.3);
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
  -webkit-transition: background 0.5s;
  transition: background 0.5s;
}
.remove-image:hover {
  background: #e54e4e;
  padding: 3px 7px 5px;
  top: -11px;
  right: -11px;
}
.remove-image:active {
  background: #e54e4e;
  top: -10px;
  right: -11px;
}
</style>
